import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
const SearchPhotographers = React.lazy(() => import('./SearchPhotographers'))

// When leaving page clear the session storage
// If your is visiting to portfolio set queries2 and clear queries
// Also if user is on same page refreshes dont clear storage



document.addEventListener('DOMContentLoaded', async () => {

  window.onpagehide = function (e) {
    const queries = JSON.parse(sessionStorage.getItem('queries'));
    if (queries) {
      sessionStorage.setItem('queries2', JSON.stringify(queries));
    }
    const specialityData = JSON.parse(sessionStorage.getItem('specialityData'));
    if (specialityData) {
      sessionStorage.setItem('specialityData2', JSON.stringify(specialityData));
    }
    sessionStorage.removeItem('specialityData');
    sessionStorage.removeItem('queries');
  }
  // window.onload = function (e) {
  //   const queries = JSON.parse(sessionStorage.getItem('queries'));
  //   if (queries) {
  //     // Restore queries in your React state or component logic
  //     console.log(queries)
  //   }
  //   // if (sessionStorage.getItem('reload-url') != null) {
  //   //   if (window.location.href == sessionStorage.getItem('reload-url')) {
  //   //     console.log('Reload');
  //   //   }
  //   // } else {
  //   //   if (sessionStorage.getItem('queries2')) {
  //   //     sessionStorage.setItem('queries', JSON.parse(sessionStorage.getItem('queries2')))
  //   //   }
  //   //   else
  //   //     sessionStorage.removeItem('queries')
  //   // }
  // }

  let specialities = await fetch('/event-type.json')
  specialities = await specialities.json()
  specialities = specialities?.event_types || []
  ReactDOM.render(
    <Suspense fallback={<div className="d-flex align-items-center justify-content-center h-100vh">
      {/* <img src={ASSETS.black_loading_gif} style={{ width: "150px" }} /> */}
    </div>}>
      <SearchPhotographers specs={specialities} />
    </Suspense>,
    document.getElementById('search_root'),
  );

})