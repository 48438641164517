import './spinner.scss';


export const StartSpinner = () => {
  let spinner = document.querySelector('#my-spinner');
  if (!spinner) {
    document.body.insertAdjacentHTML('beforeend', `
      <div id="my-spinner">
        <img src="${ASSETS.black_loading_gif}"  class="gif"  />
      </div>
    `);
    spinner = document.querySelector('#my-spinner');
  }
  spinner.classList.add('active');
}

export const StopSpinner = () => {
  const spinner = document.querySelector('#my-spinner');
  if (spinner) {
    spinner.classList.remove('active');
  }
}

 // <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
 //   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
 // </svg>