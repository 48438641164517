export const displayUserName = (user) => {

  if (!user) {
    return '';
  }

  const firstName = user.first_name || '';
  const lastName = user.last_name || '';
  if (!firstName && !lastName) {
    return user.email || user.phone || '';
  }
  return `${firstName} ${lastName}`;
};

export const showBootstrapModal = (elementId) => {
  const myModalEl = document.getElementById(elementId);
  const modal = bootstrap.Modal.getOrCreateInstance(myModalEl)
  modal.show();
};

export const hideBootstrapModal = (elementId) => {
  const myModalEl = document.getElementById(elementId);
  const modal = bootstrap.Modal.getOrCreateInstance(myModalEl)
  modal.hide();
}

export const getQueryParamValue = (paramName) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params[paramName];
};

export const getQueryParams = () => {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get(target, prop) {
      return target.get(prop)
    },
  });
}

export const getDefaultCallingCountryCode = async () => {
  const resp = await fetch("https://ipapi.co/country_calling_code");
  const data = await resp.text();
  return data || '';
}

export const normalizeWords = (str) => {
  if (!str) return "";
  str = str.split('_');
  str = str.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return str.join(' ');
}

export const normalizeArray = (arr, seperator = ", ") => {
  if (!arr || arr.length == 0) {
    return '';
  }
  return arr.filter(item => item).map(item => normalizeWords(item)).join(seperator);
}

export const getCurrentUserRole = () => {
  if (rData.user_roles.includes('photographer')) {
    return 'photographer';
  } else if (rData.user_roles.includes('client')) {
    return 'client';
  } else {
    return '';
  }
}

export const getImageFileFromDataUrl = (dataUrl, fileName = 'image') => {
  const base64 = dataUrl.split(',')[1];
  const mimeType = dataUrl.split(',')[0].split(':')[1].split(';')[0];
  const fileExt = mimeType.split('/')[1];
  if (!fileName.includes(fileExt)) {
    fileName += `.${fileExt}`;
  }
  const file = new File([base64], fileName, { type: mimeType });
  return file;
}

export const getSectionFromUrl = (sections, defaultSection) => {
  const sectionFromUrl = window.location.hash?.replace('#', '');
  let currentSection = '';
  for (const key in sections) {
    if (Object.hasOwnProperty.call(sections, key)) {
      const sectionValue = sections[key];
      if (sectionValue == sectionFromUrl) {
        currentSection = sectionValue;
      }
    }
  }
  if (!currentSection) {
    currentSection = defaultSection;
  }
  return currentSection;
}

export const getDateAsInputString = (date) => {

  if (!date) {
    return '';
  }

  if (typeof date == 'string') {
    date = new Date(date);
  }

  if (isNaN(date.getTime())) {
    return '';
  }

  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset * 60 * 1000))
  return date.toISOString().split('T')[0]
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
